import React from 'react';
import { Grid, Paper, makeStyles, Typography, Box } from '@material-ui/core';
import { monthsShort } from 'moment';
import { ViewDaySharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paperBackground: {
    backgroundColor: 'rgba(255,255,255,0.12)',
    color: theme.palette.text.primary,
  },
  paperBackgroundGreen: {
    backgroundColor: 'rgba(164,198,39,0.45)',
    color: theme.palette.text.primary,
  },
}));
const PaperDate = ({
  time,
  am,
  day,
  month,
  agenda = false,
  year,
  isGreen = false,
  isTimeSeparate = true,
  timeFrom = '',
  timeTo = '',
}) => {
  const classes = useStyles();
  return agenda ? (
    <Grid container>
      <Grid item container xs={6} alignItems='center'>
        <Typography variant='h4' color='primary' align='center'>
          {day}
        </Typography>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <Typography variant='body1' color='primary' align='center'>
            {month}
          </Typography>
          <Typography variant='body1' color='primary' align='center'>
            {year}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Box clone p={1}>
      <Paper elevation={1} className={isGreen ? classes.paperBackgroundGreen : classes.paperBackground}>
        <Grid container alignItems='center'>
          <Grid item xs={12}>
            {isTimeSeparate ? (
              <Box p={1}>
                <Typography variant='body1' color='textPrimary' align='center'>
                  {timeFrom}
                </Typography>
                <Typography variant='body1' color='textPrimary' align='center'>
                  {timeTo}
                </Typography>
              </Box>
            ) : (
              <Box p={1}>
                <Typography variant='body1' color='textPrimary' align='center'>
                  {time || day}
                </Typography>
                <Typography variant='body1' color='textPrimary' align='center'>
                  {am ? (am ? 'AM' : 'PM') : month ? month : 'PM'}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default PaperDate;
