import React, { useState, useEffect } from 'react';
import AccordionDataListNewHeight from '../../../components/accordion-data-list-new/accordion-data-list-new-height';
import SectionContainerLayoutWithFilter from '../../../components/section-container-layout-with-filter';
import { Grid, Typography } from '@material-ui/core';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import HomepageData from '../../../data/homepage.json';
import SectionContainerLayout from '../../../components/section-container-layout/section-container-layout';

export default function Press() {
  const agendaMonth = 'November';
  const currentYear = 2021;
  const [agendaDays, setAgendaDays] = useState([]);
  const [agendaLoaded, setAgendaLoaded] = useState(false);
  const [agendaIndex, setAgendaIndex] = useState();
  const [agendaData, setAgendaData] = useState([]);

  useEffect(() => {
    const _agendaDays = Object.keys(HomepageData.agenda[agendaMonth]).sort();
    setAgendaDays(_agendaDays);
    setAgendaData(HomepageData.agenda[agendaMonth][_agendaDays[0]].data);
    setAgendaLoaded(true);
    setAgendaIndex(_agendaDays[0]);
  }, []);

  const handleAgenda = (agendaIndex) => {
    setAgendaIndex(agendaIndex);
  };

  return (
    <Layout>
      <SEO lang='en' title='Press Events' />
      {agendaLoaded ? (
        <SectionContainerLayoutWithFilter
          title='PRESS EVENTS'
          filters={agendaDays}
          isDivider
          callbackFn={handleAgenda}
          isViewAll
          viewAllText={agendaMonth}>
          {agendaData.length > 0 ? (
            <Grid container item spacing={3} justify='space-between'>
              <AccordionDataListNewHeight
                data={HomepageData.agenda[agendaMonth][agendaIndex].data}
                year={currentYear}
                day={agendaIndex}
                isTimeSeparate={true}
              />
            </Grid>
          ) : (
            <></>
          )}
        </SectionContainerLayoutWithFilter>
      ) : (
        <SectionContainerLayout title='#GMIS2021 AGENDA'>
          <Typography variant='h6' color='textSecondary'>
            Loading...
          </Typography>
        </SectionContainerLayout>
      )}
    </Layout>
  );
}
